.QuestionIsToggle{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px 16px;
justify-content: center;
width: 840px;
height: 168px;
background-color: #F5F7F9;
border-radius: 6px;
}
.QuestionIsNotToggle{
box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px 16px;
background-color: white;
width: 840px;
height: 72px;

background: #FFFFFF;

/* Cloud / Light */
border-bottom: 1px solid #F5F7F9;


}